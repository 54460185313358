// import React from 'react'

import { useStaticQuery, graphql, Link } from "gatsby"

/** @jsx jsx */
import {
  jsx,
  Box, 
  Flex, 
  Button,
  Label,
  Input,
  Textarea,
  Checkbox,
} from 'theme-ui'

import { MdPhone, MdEmail, MdAssignmentInd, MdInsertComment } from "react-icons/md"

import Layout from '../components/layout'
import SEO from '../components/seo'

const AboutPage = () => {
    const data = useStaticQuery(pageQuery)
    const siteName = data.site.siteMetadata.siteTitle || "notre site"
    // const siteTagline = data.site.siteMetadata.siteTagline
    const siteDesc = data.site.siteMetadata.siteDesc

    return (
        <Layout>
            <SEO title={`A propos de ${siteName}`} />

            <h1 sx={{mt: 5}}>A propos de {siteName}</h1>
            <h2>Objectif du site</h2>
            <p>Pour le grand <b>public</b> (utilisateur.ice.s) : nous souhaitons <b>rendre la recherche de professionnels de confiance en Suisse romande plus simple</b>. Notre promesse pour les utilisateur.ice.s finaux est : "{siteDesc}"</p>
            <p>Pour les <b>pros</b> (entreprises) : nous souhaitons vous donner tous les moyens simples d'avoir une <b>présence locale convainquante en Suisse romande</b> et optimisée pour le web, par une fiche bien pensée et structurée, bien référencée, et à la technologie ultra-moderne. Plutôt que de vous laisser seul, nous vous accompagnons par un service "premium".</p>
            <h2>Fonctionnement du site</h2>
            <p>Nous établissons pour vous le profil de votre entreprise : notre équipe dispose des compétences "content marketing", "développement web" ainsi que d'une solide expérience en marketing digital. Nous mettons ces compétences à votre service en vous accompagnant dans la <b>création de votre fiche locale sur le web</b>, page dédiée, ou campagne de boost ou de contenu sur le site {siteName}.</p>
            <h2>Avantage</h2>
            <p>Plutôt que de créer vous-même sans aide votre fiche - bien souvent peu optimisée par manque de temps ou d'expérience en marketing digital - nos <b>experts du référencement local sur le web</b> vous posent les bonnes questions et vous permettent de construire une fiche totalement optimisée pour le référencement, convainquante, et qui vous différencie de votre compétition en expliquant clairement vos points forts.</p>
            <h2>Qui sommes nous ?</h2>
            <p>Notre expertise provient de notre expérience digitale complète développées depuis 2010 : en tant que stratégistes digitaux / webmarketing, agence de production multimédia, webdesigners et développeurs web. Nos services aux entreprises sont développés par l'agence digitale <a sx={{color: 'text', textDecoration: 'none'}} href="https://lyketil.com/fr">Lyketil [DIGITAL LAB] à Lausanne</a>. {siteName} permet de démocratiser ces services autour d'une plateforme utile au référencement local des professionnels, tout en facilitant grandement la recherche pour les utilisateurs.</p>
        
            <div id="contact"
                sx={{
                    mt: 6,
                    bg: 'background',
                    borderRadius: 2,
                    p: 4,
                    boxShadow: 0,
                }}
            >
              <Box
                as='form'
                name="Contact" 
                method="POST"
                netlify-honeypot="bot-field"
                data-netlify="true"
                action="/merci"
              >
                <h3 sx={{mt: 0, mb: 2, fontSize: 5, fontFamily: 'heading', fontWeight: 'bold',}}>Contactez nous !</h3>
                <p sx={{mt: 0, mb: 4}}>Une question ou demande ? Notre équipe vous répond dans les meilleurs délais.</p>

                <input type="hidden" name="bot-field" aria-label="bot field" />
                <input type="hidden" name="form-name"  aria-label="form name" value="Contact" />

                <div sx={formContainer}>
                    <Flex sx={flexContainer}>
                        <Box sx={flexTwoColumns}>
                            <Label htmlFor='first_name' sx={{alignItems: 'center',}}><MdAssignmentInd sx={{mr: 1,}} /> Prénom *</Label>
                            <Input
                                name='first_name'
                                id='first_name'
                                required
                            />
                        </Box>
        
                        <Box sx={flexTwoColumns}>
                            <Label htmlFor='last_name' sx={{alignItems: 'center',}}><MdAssignmentInd sx={{mr: 1,}} /> Nom *</Label>
                            <Input
                                name='last_name'
                                id='last_name'
                                required
                            />
                        </Box>
                    </Flex>

                    <Flex sx={flexContainer}>
                        <Box sx={flexTwoColumns}>
                            <Label htmlFor='email' sx={{alignItems: 'center',}}><MdEmail sx={{mr: 1,}} /> Votre email pro *</Label>
                            <Input
                                type="email"
                                name='email'
                                id='email'
                                required
                            />
                        </Box>
                        <Box sx={flexTwoColumns}>
                            <Label htmlFor='phone' sx={{alignItems: 'center',}}><MdPhone sx={{mr: 1,}} /> Votre numéro de téléphone pro *</Label>
                            <Input
                                type="tel"
                                name='phone'
                                id='phone'
                                required
                            />
                        </Box>
                    </Flex>
                </div>

                <div sx={formContainer}>
                    <Label htmlFor='message' sx={{alignItems: 'center',}} ><MdInsertComment sx={{mr: 1,}} /> Votre message*</Label>
                    <Textarea
                        name='message'
                        id='message'
                        rows='6'
                        mb={3}
                        required
                    />
                </div>

                <Label sx={{
                    a: {
                        mx: 2,
                    }
                }}>
                    <Checkbox 
                            name="consent"
                            required
                    /> J'ai lu et j'accepte la <Link to='/politique-de-confidentialite' sx={{color: 'text'}}>Politique de confidentialité</Link> et les <Link to='/conditions-dutilisation' sx={{color: 'text'}}>Conditions d'utilisation</Link>
                </Label>


                <Button
                    type="submit" aria-label="Envoyer"
                    sx={{
                        cursor: 'pointer',
                    }}
                >
                    Submit
                </Button>
              </Box>
          </div>    

        </Layout>
    )
}

export default AboutPage

const pageQuery = graphql`
  query aboutQuery {
    site {
      siteMetadata {
        siteTitle
        siteDesc
        siteTagline
      }
    }
  }
`

const flexContainer = { 
  justifyContent: 'space-between', 
  flexWrap: 'wrap' 
}

const flexTwoColumns = { 
  maxWidth: ['100%', '100%', '48%'],
  flex: '1 1 auto',
}

const formContainer = {
  p: 4,
  bg: 'muted',
  borderRadius: 1,
  mt: 0, mb: 3,
}